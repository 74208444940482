<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} tag type</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Name:">
                    <b-form-input size="sm" v-model="formData.name" placeholder="Enter tag type name"/>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Input type:">
                    <b-select size="sm" v-model="formData.input_type" :options="inputTypes"></b-select>
                    <error-display v-model="errors" ident="input_type"></error-display>
                </b-form-group>

                <b-form-group
                        v-if="formData.input_type === 'autocomplete' || formData.input_type === 'list'"
                        label-size="sm"
                        :label="formData.input_type === 'autocomplete' ? 'Suggestions' : 'Options'">
                    <b-form-tags size="sm" v-model="formData.tags"></b-form-tags>
                    <error-display v-model="errors" ident="tags"></error-display>
                </b-form-group>



                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.required"
                            value="1"
                            unchecked-value="0"
                    >
                        Required field?
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="required"></error-display>
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox
                            size="sm"
                            v-model="formData.in_export"
                            value="1"
                            unchecked-value="0"
                    >
                        Include in GDPR export?
                    </b-form-checkbox>
                    <error-display v-model="errors" ident="in_export"></error-display>
                </b-form-group>


                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Save tag type</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {baseApi, tagTypeDetail, tagTypeSave} from "../../modules/api/endpoints";

    export default {
        name: "TagTypeEdit",
        components: {ErrorDisplay},
        props: {
            id: null,
        },
        data: function () {
            return {
                errorLoading : false,
                inputTypes : [
                    {value : 'free_entry', text : 'Free entry'},
                    {value : 'autocomplete', text : 'Suggested input'},
                    {value : 'list', text : 'Fixed options'},
                ],
                isLoading: false,
                formData: {},
                errors : {},
                apiEndpoint: baseApi() + 'users/dropdown'
            }
        },
        created() {
            if (this.id != null) {
                this.loadModel();
            }
        },
        methods: {
            loadModel() {
                this.isLoading = true;
                getResource(tagTypeDetail(this.id)).then((resp) => {
                    var data = resp.data.success.data;
                    //TRANSFORM DATA
                    if((data.input_type === 'autocomplete' || data.input_type === 'list') && data.tags !== undefined) {
                        var tags = [];
                        _.each(data.tags, function (obj) {
                            if (obj.tag_value !== undefined) tags.push(obj.tag_value);
                        });
                        data.tags = tags;
                    } else {
                        data.tags = [];
                    }
                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(tagTypeSave,this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name' : 'tagTypes', params: {data_updated : true}}).catch(error => {});
                }).catch((err) => {
                    console.log(err);
                    if(typeof err.data.errors !== 'undefined') {
                        this.errors = err.data.errors;
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
