import { render, staticRenderFns } from "./TagTypeEdit.vue?vue&type=template&id=4033995e&scoped=true&"
import script from "./TagTypeEdit.vue?vue&type=script&lang=js&"
export * from "./TagTypeEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4033995e",
  null
  
)

export default component.exports